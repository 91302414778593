<template>
    <div>

        <button-top v-if="permissions.u" icon="EditIcon" tooltip="Editar empaque" name="Editar" :date="form.created_at"
            @action="$router.push(`/gestion-empaques/update/${form.id}`)"></button-top>
        <b-row>
            <b-col cols="3" class="font-weight-bold">
                Nombre producto
            </b-col>
            <b-col cols="3">
                {{ form.name }}
            </b-col>
        </b-row>

        <b-row class="mt-2">
            <b-col class="font-weight-bold">
                Dimensiones
            </b-col>
            <b-col>
                {{ `${formatNumber(form.longitude)} X ${formatNumber(form.broad)} X ${formatNumber(form.depth)} CM` }}
            </b-col>

            <b-col class="font-weight-bold">
                Peso
            </b-col>
            <b-col>
                {{ `${formatNumber(form.weight)} Libras` }}
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="6" class="font-weight-bold">
                Descripción
            </b-col>

        </b-row>

        <b-row class="mt-1">
            <b-col cols="6">
                {{ form.description }}
            </b-col>
        </b-row>
    </div>
</template>
<script>

export default {
    data() {
        return {
            form: {
                id: '',
                name: '',
                longitude: '',
                broad: '',
                depth: '',
                weight: '',
                description: '',
            },
        }
    },
    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.form.id = this.$route.params.id;
            await this.getPermission();
            await this.get();
        },

        async get() {
            try {
                this.loading();
                this.form = (await this.$http.get(`production/packaging/show/${this.form.id}`)).data.data;
                this.setHeaderTitle(`Id. ${this.form.id} / ${this.form.name}`);
            } catch (err) {
                if (err.response.status === 404) {
                    this.$router.push({ name: 'gestion-empaques' })
                }
                this.notify("Error", err.response.data.message, 'danger')
            } finally {
                this.not_loading();
            }
        },


    },


}
</script>